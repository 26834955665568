import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { TreeViewColumn, TreeViewOptions } from '@/components/tree-view/TreeView';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { UserType } from '@/models/User';
import { Rpc } from '@/models/Rpc';
import { Department } from '@/models/Department';
import { cloneDeep } from 'lodash';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { KwaliteitMonitorInfo } from '@/support/Info';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';

@Component<KwaliteitMonitor>({
  components: {
    ReportTypeFilter,
  },
})
export default class KwaliteitMonitor extends AbstractMoreInfoDialog {
  public $pageTitle = 'Kwaliteit Monitor';

  protected filters: KwaliteitMonitorFilters = {
    types: [],
    departments: [],
    actor: 'expert',
    workflows: [],
    opname_variants: [],
    management_info_classes: [],
  };

  protected savedFilters: {[id: string]: KwaliteitMonitorFilters} = {};

  protected moreInfo: MoreInfo[] = KwaliteitMonitorInfo;

  // departments
  protected departments: Department[] = [];

  // Organizations
  protected organizations: Organization[] | null = null;

  protected activeOrganization = '';

  // user types
  protected userTypes: UserType[] = [
    {
      key: 'expert',
      name: 'Opnemer',
    },
    {
      key: 'second_expert',
      name: 'Deskundige (indien anders dan opnemer)',
    },
    {
      key: 'tc',
      name: 'Technisch Coordinator',
    },
  ];

  // loaders
  protected isLoading = false;

  protected items: RealtimeManagementStruct[] = [];

  protected columns: TreeViewColumn[] = [
    {
      key: 'first',
      name: '# Naam',
      visible: true,
    },
    {
      key: 'report_count',
      name: '# dossiers',
      class: 'text-xs-right',
      visible: true,
    },
    {
      key: 'rejected',
      name: 'Afgekeurd',
      class: 'text-xs-right',
      visible: true,
    },
    {
      key: 'rejected_veldwerk',
      name: 'Afgekeurd Schadebepaling',
      class: 'text-xs-right',
      visible: true,
    },
  ];

  protected treeViewOptions: TreeViewOptions = {
    showStatusLabels: true,
  };

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.emitBreadcrumb();

    await this.getOrganizations();
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
      if (this.organizations && this.organizations.length && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
        this.organizations.forEach((organization) => {
          if (organization?.id) {
            this.savedFilters[organization.id] = cloneDeep(this.filters);
          }
        });
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.savedFilters[this.activeOrganization] = cloneDeep(this.filters);
    }
  }

  protected applyFilters() {
    this.isLoading = true;
    const payload = {
      signature: 'management-reports:kwaliteit-monitor',
      body: this.filters,
    };

    new Rpc().dmz(this.activeOrganization)
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.$set(this.items, `${[this.activeOrganization]}`, response.data);
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage / Kwaliteit Monitor' },
        ],
      });
  }

  protected activeOrganizationChanged(id: string) {
    this.filters = this.savedFilters[id];
  }

  protected get isSupervisorView(): boolean {
    return ! this.$store.state.Auth.has_management_info && this.$store.state.Auth.supervisor_info && this.$store.state.Auth.supervisor_info.is_supervisor;
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
    this.savedFilters[this.activeOrganization] = this.filters;
  }
}

interface RealtimeManagementStruct {
  id?: string;
  active: boolean;
  name: string;
  data: RealtimeManagementData;
  children: RealtimeManagementStruct[];
}

interface RealtimeManagementData {
  planned_reports: string;
  delivered_reports: string;
  first_time_right: string;
  doorlooptijd: string;
  doorlooptijd_percentage: string;
  costs: string;
}

interface KwaliteitMonitorFilters {
  types: string[];
  departments: string[];
  actor: string;
  workflows: string[];
  opname_variants: string[];
  management_info_classes: string[];
}
